import React from "react"
import { Link, withPrefix, graphql } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import OptInForm from "../components/optinform"
import { Carousel } from "react-responsive-carousel"
import { changeProgressBarOnScroll } from "../components/utils/index"
import "react-responsive-carousel/lib/styles/carousel.min.css"

export default class AboutPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "About")
  }

  render() {
    const { metadata, opt_in_form } = this.props.data.markdownRemark.frontmatter

    return (
      <Layout>
        <div id="about">
          <HeadMeta metadata={metadata} />
          <ProgressBar />
          <section
            className="hero hero-landing bg-gray"
            style={{
              backgroundImage: `url(/img/woman-stretching.jpg)`,
            }}
          >
            <div className="w-100 title">
              <div className="row d-flex align-items-center">
                <div className="col-md-7 offset-md-5 text order-2 order-lg-1 text-right">
                  <h1 className="text-black h2 text-center">
                    Take Charge of Your Health
                    <br className="d-none d-md-block" />
                    Through Reliable Genomic and Lifestyle Data
                  </h1>
                </div>
              </div>
            </div>
          </section>
          <section
            className="bg-gradient-blue-green"
            style={{ padding: "80px 0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1 text-center">
                  <h2 className="text-white">
                    At Advanx Health, we want to empower you to take charge of
                    your health by providing a personal genomics discovery
                    experience.
                  </h2>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="row mt-5">
              <div className="col-md-6 text-center order-md-2">
                <img
                  className="d-md-block product-gif"
                  src={withPrefix("/img/gifs/new-dashboard.gif")}
                  alt="Sample report"
                />
              </div>
              <div className="col-md-5 offset-md-1 order-md-1 px-5">
                <h3 className="text-primary-pink mb-5">What Are We Building</h3>
                <p>
                  We combine a data and tech-driven approach with human-centric
                  modal, empathy and compassion to help you tackle the most
                  persistent health challenges, like high blood pressure,
                  obesity, diabetes etc.
                </p>
                <p>
                  Through the data we collect from your screening test and DNA
                  test, our health team will be able to provide evidence-based
                  personalized recommendation on diet, fitness and lifestyle to
                  you. Our coach will walk you through step by step, monitor
                  your progress and set achieveble goals with you.
                </p>
                <p>
                  With reliable, personalized and data-driven health
                  information, we want to empower you to take charge of your own
                  health and make better decision.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6 mb-3">
                <img
                  className="img-fluid"
                  src={withPrefix("/img/meeting.jpg")}
                  alt="Company Meeting"
                />
              </div>
              <div className="col-md-5 px-5 ">
                <h3 className="text-primary-pink mb-5">
                  Why Did We Start This
                </h3>
                <p>
                  After working in the healthcare industry for a couple of
                  years, the founding team identified one of the main reason why
                  it is usually tough for people to live a healthier life.
                </p>
                <p>
                  It is the lack of understanding towards their own health
                  condition, combined with the lack of actionable steps to be
                  taken.
                </p>
                <p>
                  It is a problem that keeps us awake at night, so we gathered a
                  team of like-minded healthcare professionals to start Advanx
                  Health, with the hopes of solving this problem by:
                </p>
                <ol>
                  <li>
                    Providing understandable, concise and reliable health
                    information.
                  </li>
                  <li>
                    Providing personalised and actionable steps as a guide for
                    you to work towards living a healthier life.
                  </li>
                </ol>
              </div>
            </div>
          </section>
          <section className="bg-light">
            <div className="container text-center">
              <div className="col-md-8 offset-md-2 mb-5">
                <h2 className="text-center">The Team</h2>
              </div>
              <div className="row pb-5 justify-content-center">
                <div className="col-6 col-md-3">
                  <img
                    src={withPrefix("img/humans/weishian-square.jpg")}
                    className="img-fluid mb-4"
                    alt="Wei Shian"
                  />
                  <p className="text-primary-pink">
                    <a href="https://www.linkedin.com/in/yongweishian/">
                      <h5 className="text-primary">Yong Wei Shian</h5>
                    </a>
                    <br />
                    Co-founder & CEO
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <img
                    src={withPrefix("img/humans/john-square.jpg")}
                    className="img-fluid mb-4"
                    alt="John Yeo"
                  />
                  <p className="text-primary-pink">
                    <a href="https://www.linkedin.com/in/johnyeokehhau/">
                      <h5 className="text-primary">John Yeo</h5>
                    </a>
                    <br />
                    Co-founder & Business Operation
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <img
                    src={withPrefix("img/humans/aizuddin-square.jpg")}
                    className="img-fluid mb-4"
                    alt="Aizuddin"
                  />
                  <p className="text-primary-pink">
                    <a href="https://linkedin.com/in/aizuddinbadry">
                      <h5 className="text-primary">Aizuddin Badry</h5>
                    </a>
                    <br />
                    Tech Lead
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <img
                    src={withPrefix("img/humans/vishala-square.jpg")}
                    className="img-fluid mb-4"
                    alt="Vishala"
                  />
                  <p className="text-primary-pink">
                    <a href="https://www.linkedin.com/in/vishala-sivapalan-449a84175/">
                      <h5 className="text-primary">Vishala</h5>
                    </a>
                    <br />
                    Scientific Advancement Officer
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <img
                    src={withPrefix("img/humans/lynette-square.jpg")}
                    className="img-fluid mb-4"
                    alt="Lynette"
                  />
                  <p className="text-primary-pink">
                    <a href="https://www.linkedin.com/in/pei-yun-tan-8a8115151">
                      <h5 className="text-primary">Lynette Tan</h5>
                    </a>
                    <br />
                    Scientific Operations Technologist
                  </p>
                </div>
                <div className="col-6 col-md-3">
                  <img
                    src={withPrefix("img/humans/cheesim-square.jpg")}
                    className="img-fluid mb-4"
                    alt="Chee Sim"
                  />
                  <p className="text-primary-pink">
                    <a href="https://www.linkedin.com/in/chee-sim-lee-a9913715a/">
                      <h5 className="text-primary">Lee Chee Sim</h5>
                    </a>
                    <br />
                    Junior Software Engineer
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="" id="team-genes">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <Carousel>
                    <div>
                      <img
                        src={withPrefix("img/humans/weishian.png")}
                        className="img-fluid"
                        alt="Wei Shian"
                      />
                    </div>
                    <div>
                      <img
                        src={withPrefix("img/humans/john.png")}
                        className="img-fluid"
                        alt="John"
                      />
                    </div>
                  </Carousel>
                </div>
                <div className="col-md-6 p-3">
                  <h4>
                    Here's What We Have Discovered About Ourselves. What Would
                    You Discover?
                  </h4>
                  <p>
                    Join us in this journey of exploring your DNA.
                    <br />
                    Unlock the secrets of your genes today!
                  </p>
                  <Link
                    to={withPrefix("/products/dna-explorer")}
                    className="btn btn-primary"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <OptInForm opt_in_form={opt_in_form} />
        </div>
      </Layout>
    )
  }
}

export const aboutPageQuery = graphql`
  query AboutByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        metadata {
          title
          description
          url
          image
        }
        opt_in_form {
          header
          description
          form_name
          form_action
          form_interest
          form_submit
          form_funnel_id
          mailchimp_group_id
          mailchimp_group_list_id
          mailchimp_tag_id
        }
      }
    }
  }
`
